import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import firebase from '@firebase/app';
import { useTranslation } from 'react-i18next';
import style from './page-layout.scss';
import Header from '../../components/layout/Header';
import DateSelectorHOC from '../../components/layout/DateSelectorHOC';
import { getUsersMe, updateProfileAPI } from '../../api/users/index';
import UserInfoContext from '../../providers/userInfoContext';
import { onMessageListener } from '../../utils/firebaseInit';

import 'react-toastify/dist/ReactToastify.css';

const PageLayout = ({ children, location }) => {
	const { pathname } = location || {};
	if (pathname === '/') {
		return <Redirect to='/orders' />;
	}
	const [userInfo, setUserInfo] = useState(null);
	const showDatepicker =
    pathname === '/past-orders' || pathname?.indexOf('/orders') !== -1;
	const { i18n } = useTranslation();
	async function fetchData() {
		const data = await getUsersMe();
		if (data) {
			if (!data.lang) {
				await updateProfileAPI(data.id, {
					lang: i18n.language
				});
				setUserInfo({ ...data, lang: i18n.language });
			} else {
				setUserInfo(data);
				if (i18n.language !== data.lang) {
					i18n.changeLanguage(data.lang);
				}
			}
		}
	}
	useEffect(() => {
		fetchData();
	}, []);

	onMessageListener()
		.then(payload => {
			const { body } = payload.notification;
			toast.info(`${body}`, {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: false
			});
		})
		.catch(err => {
			toast.error(JSON.stringify(err));
		});

	return (
		<>
			{firebase.messaging.isSupported() && <ToastContainer />}
			<Header showDropdown userInfo={userInfo} />
			<main className={style.content_wrapper}>
				<UserInfoContext.Provider
					value={{ userInfo, fetchUserData: fetchData }}
				>
					{showDatepicker && <DateSelectorHOC>{children}</DateSelectorHOC>}
					{!showDatepicker && <>{children}</>}
				</UserInfoContext.Provider>
			</main>
		</>
	);
};

export default PageLayout;
